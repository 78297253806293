import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { Home } from '@views';
import ROUTES from '@constants/routes';

import '@/App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME.path} element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
