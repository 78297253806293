import React from 'react';

import styles from './Home.module.css';
import content from '@assets/content/home.json';

function Home() {
  return (
    <div className={`${styles.home} content-container`}>
      <div>
        <div className={styles.titleContainer}>
          <h1 className={styles.name}>{ content.name.full }</h1>
          <span className={styles.jobTitle}>{ content.title }</span>
        </div>
        <p className={styles.statement}>{ content.statement }</p>
        <div className={styles.contacts}>
          <span className={styles.contact}>{ content.contact.github }</span>
          <span className={styles.contact}><a href={`mailto:${content.contact.email}`}>{ content.contact.email }</a></span>
        </div>
      </div>
    </div>
  );
}

export default Home;